import { api } from '.';

const roleBaseUrl = '/roles';

export enum RoleCategoriesEnum {
  JESA = 'JESA',
  EXTERNAL_CLIENT = 'EXTERNAL_CLIENT',
  EXTERNAL_CONTRACTOR = 'EXTERNAL_CONTRACTOR',
}

export const roleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRolesByCategories: builder.query<
      any,
      { categories: RoleCategoriesEnum[]; projectId: number }
    >({
      query: ({ categories, projectId }) => ({
        url: `${roleBaseUrl}/by-category`,
        params: { categories, projectId },
      }),
    }),
  }),
});

export const { useGetRolesByCategoriesQuery } = roleApi;
