import { api } from '.';

export const platformSettingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.query<any, { params: object; baseRoute: string }>({
      query: ({ params, baseRoute }) => ({
        url: `${baseRoute}/`,
        params,
      }),
    }),
    getFilteredPlatformSettingsEntity: builder.query<
      any,
      {
        type?: string;
        page: number;
        name?: string;
        baseRoute: string;
      }
    >({
      query: ({ baseRoute, ...params }) => ({
        url: `${baseRoute}/search`,
        params,
      }),
    }),
    createUpdatePlatformSettingsEntity: builder.mutation<
      any,
      { body: any; baseRoute: string }
    >({
      query: ({ baseRoute, body }) => ({
        url: `${baseRoute}/create-update`,
        method: 'POST',
        body,
      }),
    }),
    deletePlatformSettingsEntityById: builder.mutation<
      any,
      { id: number; baseRoute: string }
    >({
      query: ({ id, baseRoute }) => ({
        url: `${baseRoute}/${id}`,
        method: 'DELETE',
      }),
    }),
    downloadLogo: builder.query<any, { url: string; projectId: number }>({
      query: ({ url, projectId }) => ({
        url: `/files/by-url`,
        method: 'GET',
        responseType: 'blob',
        cache: 'no-cache',
        params: {
          fileUrl: url,
          projectId,
        },
      }),
    }),
  }),
});

export const {
  useGetAllQuery,
  useLazyGetAllQuery,
  useLazyGetFilteredPlatformSettingsEntityQuery,
  useCreateUpdatePlatformSettingsEntityMutation,
  useDeletePlatformSettingsEntityByIdMutation,
  useLazyDownloadLogoQuery,
} = platformSettingsApi;
