import React, { useCallback, useEffect, useState } from 'react';
import { FormHelperText, Stack, ThemeProvider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import theme from 'ui/theme';
import AppTheme from 'ui/theme/app-theme';
import { ArrowLeftIcon, PlusIcon } from 'assets/svgs/componentsIcons';
import SmallCloseIcon from 'assets/svgs/componentsIcons/SmallCloseIcon';
import { BackButton } from 'components/Buttons';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import FileUpload from 'components/FileUpload';
import FormInputPlusMinus from 'components/FormInputPlusMinus';
import AppMap from 'components/AppMap';
import ActionButton from 'components/ActionButton';
import FormDate from 'components/FormDate';
import FormSwitch from 'components/FormSwitch';
import MultiselectForm from 'components/MultiSelectForm';
import { FILE_TYPE } from 'components/FileUpload/constants';
import FullPageLoader from 'components/FullPageLoader';
import ProjectSettingsFooter from 'pages/AppProjectsSettings/ProjectSettingsFooter';
import ConfirmationPopup from 'components/ConfirmationPopin';
import { PLATFORM_SETTINGS_TYPE } from 'containers/ProjectAdministrationPlatformSettings/constants';
import { POPUP_TYPE } from 'components/ConfirmationPopin/constants';
import {
  PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS,
  SETTINGS_IN_PROCESS_PROJECTS,
  SETTINGS_PROJECTS,
} from 'routes/constants';
import {
  useGetAllQuery,
  useLazyDownloadLogoQuery,
} from 'services/apis/platform-settings';
import { useGetEnumerationsByTypesQuery } from 'services/apis/enumeration';
import { useGetPlatformsQuery } from 'services/apis/platform';
import {
  useCreateUpdateProjectMutation,
  useLazyGetProjectByIdQuery,
  useSaveProjectAsDraftMutation,
} from 'services/apis/project';
import { useGetProjectAdministrationDataByTypesQuery } from 'services/apis/project-administration';
import {
  resetProjectCreation,
  setProjectCreationStatus,
  setProjectFormData,
  setProjectLocation,
  setSubmit,
  setWithWorkLocation,
  setWorkOrder,
} from 'services/slices/project-setting/project-creation';
import { ChipStyle, ChipStyleWrapper } from 'components/FormSelect/Wrapper';
import ProjectCreationPopup from 'containers/ProjectCreationPopup';
import { PROJECT_CREATION_STATUS } from 'helpers/constants';
import prepareData from 'utils/helpers/requestPartMapper';
import handleBlobReceived from 'utils/blobUtils';
import { DEFAULT_FILE_NAME } from 'utils/constants/messages';
import AutocompleteInput from 'components/AutocompleteInput';
import {
  useLazyGetCountriesListByNameQuery,
  useLazyGetRegionsByCountryQuery,
} from 'services/apis/countries';
import usePrivilegeByFeature from 'utils/hooks/usePrivilegeByFeature';
import { Features, Privileges } from 'utils/constants/Features';
import { useLazyGetPortfoliosByProgramCodeQuery } from 'services/apis/portfolio';
import { getConnectedUserState } from 'services/apis/user';
import {
  COLLAB_JPASS_PLATFORMS,
  MOROCCO_CODE,
  PROJECT_DATA_ENUM,
  PROJECT_DATA_TYPES,
  PROJECT_STATES,
  REQUIRED_ERROR,
} from './constants';
import {
  makeSelectPicture,
  makeSelectProjectCreationStatus,
  makeSelectProjectFormData,
  makeSelectSubmit,
  makeSelectWorkOrder,
} from './selectors';
import ProjectsSettingsProjectCreationWrapper from './Wrapper';

const stateSelector = createStructuredSelector({
  projectFormData: makeSelectProjectFormData,
  workOrder: makeSelectWorkOrder,
  submit: makeSelectSubmit,
  picture: makeSelectPicture,
  projectCreationStatus: makeSelectProjectCreationStatus,
});

function ProjectsSettingsProjectCreation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [saveLeave, setSaveLeave] = useState(false);
  const [workOrderErrorMessage, setWorkOrderErrorMessage] = useState(null);

  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [selectedPortfolios, setSelectedPortfolios] = useState([]);
  const [selectedWOs, setSelectedWOs] = useState([]);
  const [signedAuthorization, setSignedAuthorization] = useState([]);
  const [projectPicture, setProjectPicture] = useState([]);

  const [countryName, setCountryName] = useState('');

  const [doDownloadLogo, { data: picture }] = useLazyDownloadLogoQuery();
  const [doDownloadSignedAuthorization, { data: signedAuthorizationPicture }] =
    useLazyDownloadLogoQuery();

  const { projectId } = useParams();
  const { projectFormData, workOrder, submit, projectCreationStatus } =
    useSelector(stateSelector);

  const { data: projectData } =
    useGetProjectAdministrationDataByTypesQuery(PROJECT_DATA_TYPES);
  const { data: enumerations } = useGetEnumerationsByTypesQuery({
    types: PROJECT_DATA_ENUM,
    projectId,
  });
  const { data: platforms, error: getPlatformsError } = useGetPlatformsQuery();
  const [doGetProjectById, { data: project, isLoading: getProjectLoading }] =
    useLazyGetProjectByIdQuery();
  const [
    doSaveProjectAsDraft,
    {
      data: draftProjectId,
      isSuccess: projectSaveAsDraftSuccess,
      isLoading: projectSaveAsDraftLoading,
    },
  ] = useSaveProjectAsDraftMutation();
  const [
    doCreateOrUpdateProject,
    {
      data: createdOrUpdateProjectId,
      isSuccess: projectCreateOrUpdateSuccess,
      isLoading: createLoading,
    },
  ] = useCreateUpdateProjectMutation();
  const { data: clients, error: getClientsError } = useGetAllQuery({
    baseRoute: PLATFORM_SETTINGS_TYPE.CLIENT.baseRoute,
    params: { projectId },
  });
  const [citiesList, setCitiesList] = useState([]);

  const [doGetRegionsByCountryQuery, { data: regionsList = [] }] =
    useLazyGetRegionsByCountryQuery();
  const [doGetPortfoliosByProgramCode, { data: portfolios = [] }] =
    useLazyGetPortfoliosByProgramCodeQuery();

  const [doGetCountriesListByNameQuery, { data: countries = [] }] =
    useLazyGetCountriesListByNameQuery();
  const { data: connectedUser } = getConnectedUserState();

  useEffect(() => {
    if (countryName?.length >= 3) {
      doGetCountriesListByNameQuery({ projectId, name: countryName });
    } else if (countryName?.length === 0) {
      dispatch(
        setProjectFormData({
          ...projectFormData,
          country: null,
          region: null,
          city: null,
        }),
      );
    }
  }, [countryName]);
  useEffect(() => {
    if (projectFormData?.country?.code === MOROCCO_CODE) {
      doGetRegionsByCountryQuery({
        projectId,
        countryCode: projectFormData?.country?.code,
      });
    }
  }, [projectFormData?.country]);
  useEffect(() => {
    if (projectFormData?.program) {
      doGetPortfoliosByProgramCode({ programCode: projectFormData?.program, projectId })
        .unwrap()
        .then((data) => {
          setSelectedPortfolios(
            data?.filter((portfolio) =>
              project?.portfolios?.map((p) => p.code)?.includes(portfolio.code),
            ),
          );
        });
    }
  }, [projectFormData?.program]);

  useEffect(() => {
    if (projectFormData?.region !== undefined && regionsList.length > 0) {
      const cities = regionsList.find(
        (region) => region.label === projectFormData?.region,
      )?.cities;
      setCitiesList(cities);
    }
  }, [projectFormData?.region, regionsList]);

  const navigateToProjectSettings = () => {
    navigate(
      project?.projectCreationStatus === PROJECT_CREATION_STATUS.DRAFT
        ? SETTINGS_IN_PROCESS_PROJECTS
        : SETTINGS_PROJECTS,
    );
  };

  const onCancelSuccess = () => {
    setShowSuccessPopup(false);
    navigateToProjectSettings();
  };

  useEffect(() => {
    if (project) {
      setCountryName(project.country?.name ?? '');
      dispatch(setProjectCreationStatus(project?.projectCreationStatus));
      dispatch(setWithWorkLocation(project?.withWorkLocation));
      dispatch(setProjectFormData(project));
      setSelectedPlatforms(project.platforms);
      setSelectedPhases(project.phase);
      setSelectedPortfolios(project.portfolios);
      setSelectedWOs(
        project.workOrders.map((wo, index) => ({ ...wo, index: index + 1 })),
      );
    }
  }, [dispatch, project]);

  useEffect(() => {
    if (picture) {
      handleBlobReceived(picture, DEFAULT_FILE_NAME);
    }
  }, [picture]);
  useEffect(() => {
    if (signedAuthorizationPicture) {
      handleBlobReceived(signedAuthorizationPicture, DEFAULT_FILE_NAME);
    }
  }, [signedAuthorizationPicture]);
  const navigateToProjectMembersEdition = useCallback(() => {
    const updateProjectMembersPath = generatePath(PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS, {
      projectId: createdOrUpdateProjectId || projectId,
    });

    navigate(updateProjectMembersPath);
  }, [createdOrUpdateProjectId, projectId, navigate]);

  useEffect(() => {
    dispatch(resetProjectCreation());
    if (projectId) {
      doGetProjectById(projectId);
    }
  }, [dispatch]);

  useEffect(() => {
    if (createdOrUpdateProjectId && projectCreateOrUpdateSuccess && !isDraft) {
      if (!saveLeave) {
        dispatch(setWithWorkLocation(projectFormData?.withWorkLocation));
        navigateToProjectMembersEdition();
      } else {
        setShowSuccessPopup(true);
      }
    }
  }, [dispatch, createdOrUpdateProjectId, navigateToProjectMembersEdition]);

  const addWorkOrder = () => {
    if (
      selectedWOs.map((wo) => wo.number).includes(workOrder.number) &&
      !workOrder.index
    ) {
      setWorkOrderErrorMessage('Work Order With this number already exist');
    } else if (!workOrder.workOrderType) {
      setWorkOrderErrorMessage('WorkOrderType is required');
    } else if (workOrder.number && workOrder.workOrderType) {
      if (!workOrder.index) {
        const newWorkOrder = {
          ...workOrder,
          index: selectedWOs.length + 1,
        };
        setSelectedWOs((existingWorkOrders) => [...existingWorkOrders, newWorkOrder]);
      } else {
        setSelectedWOs((existingWorkOrders) =>
          existingWorkOrders.map((wo) => (wo.index !== workOrder.index ? wo : workOrder)),
        );
      }
      setWorkOrderErrorMessage(null);
      dispatch(setWorkOrder({}));
    }
  };

  const handleOpenCloseModal = () => {
    setOpenModal(false);
  };

  const checkDatesValidity = () => {
    const startDate = new Date(projectFormData?.startDate);
    const contractualEndDate = new Date(projectFormData?.contractualEndDate);
    const forecastDate = new Date(projectFormData?.forecastDate);
    return !(
      startDate?.getTime() > contractualEndDate?.getTime() ||
      startDate?.getTime() > forecastDate?.getTime()
    );
  };
  const checkFormValidityDraft = () =>
    projectFormData?.number &&
    projectFormData?.name &&
    selectedPhases?.length > 0 &&
    selectedPlatforms?.length > 0 &&
    projectFormData?.bu &&
    projectFormData?.client &&
    projectFormData.startDate &&
    projectFormData.contractualEndDate &&
    projectFormData.typeContract &&
    projectFormData.commercialContract &&
    projectFormData.status;

  const checkFormValidity = () =>
    checkFormValidityDraft() &&
    projectFormData?.bu &&
    projectFormData.projectSize &&
    projectFormData.riskClassification &&
    projectFormData.client &&
    projectFormData.typeContract &&
    projectFormData.commercialContract &&
    projectFormData.scope &&
    projectFormData.target &&
    projectFormData.startDate &&
    projectFormData.contractualEndDate &&
    projectFormData.forecastDate &&
    projectFormData.homeOfficeLocation &&
    (!projectFormData?.atRisk ||
      (projectFormData.maxDate && signedAuthorization?.length > 0)) &&
    ((projectFormData.status === PROJECT_STATES.CLOSED && projectFormData.closeDate) ||
      (projectFormData.status === PROJECT_STATES.ON_HOLD && projectFormData.onHoldDate) ||
      ![PROJECT_STATES.ON_HOLD, PROJECT_STATES.CLOSED].includes(
        projectFormData.status,
      )) &&
    checkDatesValidity() &&
    projectFormData.country &&
    projectFormData.region &&
    projectFormData.city;

  const handleSetUpdatedLocation = (location) => {
    dispatch(
      setProjectLocation({
        latitude: location.lat,
        longitude: location.lng,
      }),
    );
  };

  const deleteWO = (index) => {
    setSelectedWOs((existingWOs) => existingWOs.filter((wo) => wo.index !== index));
  };
  const addRemoveFromPhase = (e) => {
    const selectedPhaseCode = enumerations?.PHASE?.filter((phase) =>
      e.target.value.includes(phase?.label),
    ).map((p) => p?.code);
    setSelectedPhases([...selectedPhaseCode]);
  };
  const addRemoveFromPortfolios = (e) => {
    const protfolioItems = portfolios?.filter((p) => e.target.value.includes(p?.name));
    setSelectedPortfolios([...protfolioItems]);
  };

  const handleDeleteChip = (deletedValue) => {
    const selectedItem = enumerations?.PHASE?.filter(
      (item) => item.label === deletedValue,
    );
    const updatedSelectedValues = selectedPhases.filter(
      (value) => value !== selectedItem[0]?.code,
    );
    setSelectedPhases(updatedSelectedValues);
  };
  const handleDeletePortfolio = (deletedValue) => {
    const selectedItem = portfolios?.filter((item) => item.name === deletedValue);
    const updatedSelectedValues = selectedPortfolios.filter(
      (value) => value?.code !== selectedItem[0]?.code,
    );
    setSelectedPortfolios(updatedSelectedValues);
  };

  const addRemoveFromPlatforms = (selectedList) => {
    const collabName = platforms?.find(
      (p) => p.code === COLLAB_JPASS_PLATFORMS.COLLAB,
    )?.name;
    const jpassName = platforms?.find(
      (p) => p.code === COLLAB_JPASS_PLATFORMS.JPASS,
    )?.name;
    if (selectedList?.includes(jpassName) && !selectedList?.includes(collabName)) {
      selectedList.push(collabName);
    }
    const selectedPlatform = platforms?.filter((platform) =>
      selectedList.includes(platform?.name),
    );
    setSelectedPlatforms([...selectedPlatform]);
  };

  const handleDeleteChipPlatform = (deletedValue) => {
    const selectedItem = platforms?.find((p) => p?.name === deletedValue);

    const selectedPlatformIds = selectedPlatforms
      ?.filter((p) => selectedItem?.id !== p?.id)
      .map((p) => p.id);

    const newSelectedPlatformNames = platforms
      ?.filter((platform) => selectedPlatformIds?.includes(platform?.id))
      ?.map((p) => p?.name);

    addRemoveFromPlatforms(newSelectedPlatformNames);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setIsDraft(false);
  };

  const handleOpenDraftModal = () => {
    setIsDraft(true);
    if (checkFormValidityDraft()) {
      setOpenDraftModal(true);
    }
  };

  const handleSaveProject = (saveProject) => {
    const body = {
      name: 'projectDto',
      data: {
        ...projectFormData,
        workOrders: selectedWOs,
        phase: selectedPhases,
        platforms: selectedPlatforms,
        portfolios: selectedPortfolios,
      },
    };
    let files = [];
    if (signedAuthorization[0]?.path !== project?.signedAuthorizationUrl) {
      files = [
        ...files,
        {
          fileName: 'signedAuthorization',
          blobFile: signedAuthorization,
        },
      ];
    }
    if (projectPicture[0]?.path !== project?.picture) {
      files = [
        ...files,
        {
          fileName: 'projectImage',
          blobFile: projectPicture,
        },
      ];
    }

    const projectPayload = prepareData(body, files);
    saveProject(projectPayload);
  };
  const handleSaveContinue = (saveLeaveValue) => {
    setSaveLeave(saveLeaveValue);
    dispatch(setSubmit(true));
    if (checkFormValidity()) {
      handleSaveProject(doCreateOrUpdateProject);
    }
  };

  const handleSaveDraftProject = () => {
    handleSaveProject(doSaveProjectAsDraft);
  };

  const handleAtRisk = () => {
    if (
      projectFormData?.atRisk &&
      (projectFormData.maxDate ||
        projectFormData.maximumAmount ||
        signedAuthorization?.length > 0)
    ) {
      dispatch(
        setProjectFormData({
          ...projectFormData,
          maxDate: undefined,
          maximumAmount: undefined,
          atRisk: !projectFormData?.atRisk,
        }),
      );
      if (signedAuthorization) {
        setSignedAuthorization([]);
      }
    } else {
      dispatch(
        setProjectFormData({
          ...projectFormData,
          atRisk: !projectFormData?.atRisk,
        }),
      );
    }
  };

  useEffect(() => {
    if (project?.id) {
      if (project?.signedAuthorizationUrl)
        setSignedAuthorization([
          { path: project?.signedAuthorizationUrl, downloaded: true },
        ]);
      if (project?.picture) {
        setProjectPicture([{ path: project?.picture, downloaded: true }]);
      }
    }
  }, [project]);

  const title = `Project ${projectId ? 'update' : 'creation'}`;
  const subTitle = projectId ? '' : 'Add new projects settings to the platform';
  const hasReadPrivilege = usePrivilegeByFeature(
    Features.DS_PROJECT_GENERAL_INFORMATION,
    Privileges.READ,
  );
  const hasWritePrivilege = usePrivilegeByFeature(
    Features.DS_PROJECT_GENERAL_INFORMATION,
    Privileges.WRITE,
  );
  return (
    <ThemeProvider theme={AppTheme}>
      {(createLoading || getProjectLoading) && <FullPageLoader />}
      <ProjectsSettingsProjectCreationWrapper>
        <Stack className="top-header">
          <Stack className="heading-area">
            <BackButton onClick={navigateToProjectSettings} type="button">
              <ArrowLeftIcon />
            </BackButton>
            <Stack className="page-title-area">
              <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
                {title}
              </Typography>
            </Stack>
          </Stack>

          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.main">
            {subTitle}
          </Typography>
        </Stack>
        {hasReadPrivilege && (
          <Stack className="projectCreationForm">
            <Stack>
              <Stack className="form-search-area">
                <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
                  Project handover information
                </Typography>
                <FormInput
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({ ...projectFormData, number: e.target.value }),
                    )
                  }
                  value={projectFormData?.number}
                  label="Project Number*"
                  error={(submit || isDraft) && !projectFormData.number && REQUIRED_ERROR}
                  placeholder="Q1009011"
                  disabled={!hasWritePrivilege}
                />
                {(getPlatformsError || getClientsError) && (
                  <p className="errorMsg">{getPlatformsError || getClientsError}</p>
                )}
              </Stack>

              <Stack className="form-split">
                <FormInput
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({ ...projectFormData, name: e.target.value }),
                    )
                  }
                  value={projectFormData?.name}
                  label="Project Name*"
                  error={(submit || isDraft) && !projectFormData.name && REQUIRED_ERROR}
                  placeholder="Project Name"
                  disabled={!hasWritePrivilege}
                />
                <FormSelect
                  disabled={!connectedUser?.admin}
                  menuItems={projectData?.BU}
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        bu: e.target.value?.code,
                      }),
                    )
                  }
                  value={projectData?.BU?.find((bu) => bu.code === projectFormData?.bu)}
                  label="BU*"
                  valueKey="name"
                  error={(submit || isDraft) && !projectFormData.bu && REQUIRED_ERROR}
                />
              </Stack>
              <Stack className="form-split">
                <FormSelect
                  menuItems={projectData?.SECTOR}
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        sector: e.target.value?.code,
                      }),
                    )
                  }
                  value={projectData?.SECTOR?.find(
                    (sector) => sector.code === projectFormData?.sector,
                  )}
                  label="Sector"
                  valueKey="name"
                  disabled={!connectedUser?.admin}
                />
                <FormSelect
                  menuItems={projectData?.PROGRAM}
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        program: e.target.value?.code,
                      }),
                    )
                  }
                  value={projectData?.PROGRAM?.find(
                    (program) => program.code === projectFormData?.program,
                  )}
                  label="Program"
                  valueKey="name"
                  disabled={!connectedUser?.admin}
                />
              </Stack>

              <Stack className="form-split subProgram">
                <MultiselectForm
                  options={portfolios?.map((p) => p?.name)}
                  label="Sub Program (Portfolio)"
                  value={portfolios
                    ?.filter((portfolio) =>
                      selectedPortfolios?.map((p) => p.code)?.includes(portfolio.code),
                    )
                    ?.map((p) => p?.name)}
                  onChange={(e) => {
                    addRemoveFromPortfolios(e);
                  }}
                  handleDelete={handleDeletePortfolio}
                  valueKey="label"
                  disabled={!connectedUser?.admin}
                />
                <Stack />
              </Stack>

              <Stack className="form-split">
                <FormSelect
                  menuItems={enumerations?.PROJECT_SIZE}
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        projectSize: e.target.value?.code,
                      }),
                    )
                  }
                  value={enumerations?.PROJECT_SIZE?.find(
                    (size) => size.code === projectFormData?.projectSize,
                  )}
                  label="Project size*"
                  error={submit && !projectFormData.projectSize}
                  valueKey="label"
                  disabled={!hasWritePrivilege}
                />
                <Stack className="switchForm">
                  <FormSwitch
                    labelColor={theme.palette.text.main}
                    label="Is it a project with work locations ?"
                    checked={projectFormData?.withWorkLocation}
                    onChange={() => {
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          withWorkLocation: !projectFormData?.withWorkLocation,
                        }),
                      );
                    }}
                    disabled={!hasWritePrivilege}
                  />
                  <FormSwitch
                    labelColor={theme.palette.text.main}
                    label="At-Risk"
                    checked={projectFormData?.atRisk}
                    onChange={handleAtRisk}
                    disabled={!hasWritePrivilege}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              {projectFormData?.atRisk && (
                <Stack className="form-split mb-25">
                  <FileUpload
                    onChoose={(file) => setSignedAuthorization(file)}
                    label="Signed authorization*"
                    uploadedFiles={signedAuthorization}
                    handleFileDelete={() => {
                      setSignedAuthorization([]);
                    }}
                    maxSize={5000000}
                    multiFiles={false}
                    acceptedExtensions={{ 'image/*': ['.jpeg', '.jpg', '.png', '.pdf'] }}
                    supportsList={[
                      FILE_TYPE.PNG,
                      FILE_TYPE.JPEG,
                      FILE_TYPE.JPG,
                      FILE_TYPE.PDF,
                    ]}
                    error={
                      submit &&
                      (!projectFormData?.atRisk || !signedAuthorization?.length > 0)
                    }
                    isDownload={signedAuthorization.length > 0}
                    handleDownloadFile={
                      signedAuthorization[0]?.downloaded &&
                      (() =>
                        doDownloadSignedAuthorization({
                          url: project?.signedAuthorizationUrl,
                          projectId,
                        }))
                    }
                    disabled={!hasWritePrivilege}
                  />
                  <Stack className="atRiskForms">
                    <FormDate
                      onChange={(e) => {
                        dispatch(
                          setProjectFormData({
                            ...projectFormData,
                            maxDate: e,
                          }),
                        );
                      }}
                      value={projectFormData?.maxDate}
                      myLabel="Maximum Date*"
                      error={
                        submit && (!projectFormData?.atRisk || !projectFormData.maxDate)
                      }
                      disabled={!hasWritePrivilege}
                    />
                    <FormInput
                      onChange={(e) => {
                        dispatch(
                          setProjectFormData({
                            ...projectFormData,
                            maximumAmount: e.target.value,
                          }),
                        );
                      }}
                      value={projectFormData?.maximumAmount}
                      label="Maximum Amount"
                      placeholder="0.0"
                      haveCurrency
                      disabled={!hasWritePrivilege}
                    />
                  </Stack>
                </Stack>
              )}
              <Stack className="form-split mt-25">
                <FormSelect
                  menuItems={enumerations?.RISK_CLASSIFICATION}
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        riskClassification: e.target.value?.code,
                      }),
                    )
                  }
                  value={enumerations?.RISK_CLASSIFICATION?.find(
                    (risk) => risk.code === projectFormData?.riskClassification,
                  )}
                  label="Risk Classification*"
                  error={submit && !projectFormData.riskClassification}
                  valueKey="label"
                  disabled={!hasWritePrivilege}
                />
                <FormInput
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        risksDeterminingRiskClassification: e.target.value,
                      }),
                    )
                  }
                  value={projectFormData?.risksDeterminingRiskClassification}
                  label="Risks Determining Risk Classification"
                  placeholder="Risks Determining Risk Classification"
                  disabled={!hasWritePrivilege}
                />
              </Stack>
              <Stack className="form-split">
                <FormSelect
                  menuItems={enumerations?.PROJECT_STATE}
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        status: e.target.value?.code,
                        onHoldDate: undefined,
                        closeDate: undefined,
                      }),
                    )
                  }
                  value={enumerations?.PROJECT_STATE?.find(
                    (state) => state.code === projectFormData?.status,
                  )}
                  label="Status*"
                  valueKey="label"
                  error={(submit || isDraft) && !projectFormData.status && REQUIRED_ERROR}
                  disabled={!hasWritePrivilege}
                />
                <Stack className="form-split">
                  <Stack className="fullWidth">
                    {projectFormData?.status === PROJECT_STATES.CLOSED && (
                      <FormDate
                        onChange={(e) =>
                          dispatch(
                            setProjectFormData({
                              ...projectFormData,
                              closeDate: e,
                            }),
                          )
                        }
                        value={projectFormData?.closeDate}
                        myLabel="Close date*"
                        error={
                          submit &&
                          projectFormData?.status === PROJECT_STATES.CLOSED &&
                          !projectFormData.closeDate
                        }
                        disabled={!hasWritePrivilege}
                      />
                    )}
                    {projectFormData?.status === PROJECT_STATES.ON_HOLD && (
                      <FormDate
                        onChange={(e) =>
                          dispatch(
                            setProjectFormData({
                              ...projectFormData,
                              onHoldDate: e,
                            }),
                          )
                        }
                        value={projectFormData?.onHoldDate}
                        myLabel="Onhold date*"
                        error={
                          submit &&
                          projectFormData?.status === PROJECT_STATES.ON_HOLD &&
                          !projectFormData.onHoldDate
                        }
                        disabled={!hasWritePrivilege}
                      />
                    )}
                  </Stack>
                </Stack>
              </Stack>
              <Stack className="form-split">
                <Stack className="form-split">
                  <MultiselectForm
                    options={enumerations?.PHASE?.map((phase) => phase?.label)}
                    label="Phase*"
                    value={enumerations?.PHASE?.filter((phase) =>
                      selectedPhases?.includes(phase.code),
                    )?.map((p) => p?.label)}
                    onChange={(e) => {
                      addRemoveFromPhase(e);
                    }}
                    handleDelete={(e) => handleDeleteChip(e)}
                    error={(submit || isDraft) && selectedPhases.length === 0}
                    valueKey="label"
                    disabled={!hasWritePrivilege}
                  />
                  <FormSelect
                    menuItems={enumerations?.PROJECT_GREEN_FIELD}
                    onChange={(e) =>
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          greenfield: e.target.value?.code,
                        }),
                      )
                    }
                    value={enumerations?.PROJECT_GREEN_FIELD?.find(
                      (state) => state.code === projectFormData?.greenfield,
                    )}
                    error={submit && !projectFormData.greenfield}
                    label="GreenField*"
                    valueKey="label"
                    disabled={!hasWritePrivilege}
                  />
                </Stack>
                <FileUpload
                  onChoose={(file) => setProjectPicture(file)}
                  handleFileDelete={() => setProjectPicture([])}
                  label="Picture"
                  uploadedFiles={projectPicture}
                  multiFiles={false}
                  maxSize={5000000}
                  acceptedExtensions={{ 'image/*': ['.jpeg', '.jpg', '.png'] }}
                  supportsList={[FILE_TYPE.PNG, FILE_TYPE.JPEG, FILE_TYPE.JPG]}
                  isDownload={projectPicture?.length > 0}
                  handleDownloadFile={
                    projectPicture[0]?.downloaded &&
                    (() => doDownloadLogo({ url: project.picture, projectId }))
                  }
                  disabled={!hasWritePrivilege}
                />
              </Stack>
            </Stack>

            <Stack>
              <Stack className="mt-25 mb-40">
                <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
                  Work orders
                </Typography>
              </Stack>
              <Stack className="form-one-item">
                <Stack className="flex__row--center-space">
                  <FormInput
                    label="Work order Number"
                    classname="fullWidth mr-15 "
                    placeholder="Work order Number"
                    onChange={(e) =>
                      dispatch(
                        setWorkOrder({
                          ...workOrder,
                          number: e.target.value,
                        }),
                      )
                    }
                    value={workOrder?.number}
                    disabled={!hasWritePrivilege}
                  />
                  <FormSelect
                    customClassName="customHeight fullWidth mr-15"
                    menuItems={enumerations?.WORK_ORDER_TYPE}
                    onChange={(e) =>
                      dispatch(
                        setWorkOrder({
                          ...workOrder,
                          workOrderType: e.target.value?.code,
                        }),
                      )
                    }
                    value={enumerations?.WORK_ORDER_TYPE?.find(
                      (type) => type.code === workOrder?.workOrderType,
                    )}
                    label="Work Order Type"
                    valueKey="label"
                  />
                  {workOrderErrorMessage && (
                    <FormHelperText className="errorMsg">
                      {workOrderErrorMessage}
                    </FormHelperText>
                  )}
                  <ActionButton
                    label={<PlusIcon viewBox="3 3 10 10" width="10" height="10" />}
                    typoVariant="subtitle1_HelveticaNeue_Medium"
                    className="addWoButton"
                    onClick={addWorkOrder}
                    disabled={!hasWritePrivilege}
                  />
                </Stack>
                <ChipStyleWrapper>
                  {selectedWOs.map((wo) => (
                    <Stack key={wo.id} className="flex__row--center">
                      <ChipStyle
                        className="chipStyle"
                        key={wo.number}
                        label={wo.number}
                        onDelete={() => deleteWO(wo.index)}
                        onClick={() => dispatch(setWorkOrder(wo))}
                        deleteIcon={
                          <Stack>
                            <SmallCloseIcon classname="deleteIcon" />
                          </Stack>
                        }
                        disabled={!hasWritePrivilege}
                      />
                    </Stack>
                  ))}
                </ChipStyleWrapper>
              </Stack>
            </Stack>
            <Stack className="mt-25">
              <Stack className="mb-40">
                <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
                  Client information
                </Typography>
              </Stack>
              <Stack className="form-split">
                <FormSelect
                  menuItems={clients}
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        client: e.target.value,
                      }),
                    )
                  }
                  value={clients?.find((c) => c.id === projectFormData?.client?.id)}
                  error={(submit || isDraft) && !projectFormData.client && REQUIRED_ERROR}
                  label="Client*"
                  valueKey="name"
                  disabled={!connectedUser?.admin}
                />
                <AutocompleteInput
                  label="Country*"
                  isWithCheckbox={false}
                  placeholder="Type 3 characters to search..."
                  handleOnInputChange={(_, value, reason) => {
                    if (reason === 'input') {
                      setCountryName(value);
                    }
                  }}
                  handleOnChange={(_, values) => {
                    setCountryName(values[0].name);
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        country: values[0],
                        region: null,
                        city: null,
                      }),
                    );
                  }}
                  inputValue={countryName}
                  options={countries}
                  valueKey="name"
                  error={submit && !projectFormData?.country}
                  disabled={!hasWritePrivilege}
                />
              </Stack>
              {projectFormData?.country?.code === MOROCCO_CODE ? (
                <Stack className="form-split">
                  <FormSelect
                    menuItems={regionsList}
                    onChange={(e) =>
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          region: e.target.value.label,
                        }),
                      )
                    }
                    value={regionsList?.find((r) => r.label === projectFormData?.region)}
                    error={submit && !projectFormData.region}
                    label="Region*"
                    valueKey="label"
                    disabled={!hasWritePrivilege}
                  />
                  <FormSelect
                    menuItems={citiesList}
                    onChange={(e) =>
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          city: e.target.value.label,
                        }),
                      )
                    }
                    value={citiesList?.find((c) => c.label === projectFormData?.city)}
                    error={submit && !projectFormData.city}
                    label="City*"
                    valueKey="label"
                    disabled={!hasWritePrivilege}
                  />
                </Stack>
              ) : (
                <Stack className="form-split">
                  <FormInput
                    label="Region *"
                    placeholder="Enter Region"
                    onChange={(e) =>
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          region: e.target.value,
                        }),
                      )
                    }
                    value={projectFormData?.region}
                    isValid={!(submit && !projectFormData?.region)}
                    disabled={!hasWritePrivilege}
                  />
                  <FormInput
                    label="City *"
                    placeholder="Enter City"
                    onChange={(e) =>
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          city: e.target.value,
                        }),
                      )
                    }
                    value={projectFormData?.city}
                    isValid={!(submit && !projectFormData?.city)}
                    disabled={!hasWritePrivilege}
                  />
                </Stack>
              )}
              <Stack className="form-split">
                <FormInput
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        sponsor: e.target.value,
                      }),
                    )
                  }
                  value={projectFormData?.sponsor}
                  label="Sponsor Name"
                  placeholder="Sponsor Name"
                  disabled={!hasWritePrivilege}
                />
                <FormInput
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        jvPartner: e.target.value,
                      }),
                    )
                  }
                  value={projectFormData?.jvPartner}
                  label="JV Partner"
                  placeholder="JV Partner"
                  disabled={!hasWritePrivilege}
                />
              </Stack>
            </Stack>
            <Stack className="mt-30">
              <Stack className="mb-40">
                <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
                  Contract Key information
                </Typography>
              </Stack>
              <Stack className="form-split">
                <Stack className="form-split">
                  <FormSelect
                    menuItems={enumerations?.PROJECT_TYPE_CONTRACT}
                    onChange={(e) =>
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          typeContract: e.target.value?.code,
                        }),
                      )
                    }
                    value={enumerations?.PROJECT_TYPE_CONTRACT?.find(
                      (strategy) => strategy.code === projectFormData?.typeContract,
                    )}
                    label="Type Contract*"
                    error={
                      (submit || isDraft) &&
                      !projectFormData.typeContract &&
                      REQUIRED_ERROR
                    }
                    valueKey="label"
                    disabled={!connectedUser?.admin}
                  />
                  <FormSelect
                    menuItems={enumerations?.PROJECT_COMMERCIAL_CONTRACT}
                    onChange={(e) =>
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          commercialContract: e.target.value?.code,
                        }),
                      )
                    }
                    value={enumerations?.PROJECT_COMMERCIAL_CONTRACT?.find(
                      (strategy) => strategy.code === projectFormData?.commercialContract,
                    )}
                    label="Commercial Contract*"
                    error={
                      (submit || isDraft) &&
                      !projectFormData.commercialContract &&
                      REQUIRED_ERROR
                    }
                    valueKey="label"
                    disabled={!connectedUser?.admin}
                  />
                </Stack>
                <FormSelect
                  menuItems={enumerations?.PROJECT_SCOPE}
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        scope: e.target.value?.code,
                      }),
                    )
                  }
                  value={enumerations?.PROJECT_SCOPE?.find(
                    (scope) => scope.code === projectFormData?.scope,
                  )}
                  label="Scope*"
                  error={submit && !projectFormData.scope}
                  valueKey="label"
                  disabled={!connectedUser?.admin}
                />
                <FormInput
                  label="JESA Contract (MMAD)"
                  onChange={(e) =>
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        jesaContract: e.target.value,
                      }),
                    )
                  }
                  value={projectFormData?.jesaContract}
                  placeholder="0.0"
                  haveCurrency
                  disabled={!hasWritePrivilege}
                />
                <Stack className="form-split">
                  <FormInput
                    onChange={(e) =>
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          target: e.target.value,
                        }),
                      )
                    }
                    value={projectFormData?.target}
                    label="Days target in project contract*"
                    error={submit && !projectFormData.target && REQUIRED_ERROR}
                    placeholder="0.0"
                    disabled={!hasWritePrivilege}
                  />
                  <FormInputPlusMinus
                    onChange={(e) => {
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          workshareSplit: e,
                        }),
                      );
                    }}
                    value={projectFormData?.workshareSplit}
                    label="Workshare % Split"
                    fullLabelWidth
                    min={1}
                    max={5}
                    disabled={!hasWritePrivilege}
                  />
                </Stack>
                <FormDate
                  disable={projectFormData?.id}
                  onChange={(date) => {
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        startDate: date,
                      }),
                    );
                  }}
                  value={projectFormData?.startDate}
                  error={
                    (submit || isDraft) && !projectFormData.startDate && REQUIRED_ERROR
                  }
                  myLabel="Start Date*"
                  disabled={!connectedUser?.admin}
                />
                {!checkDatesValidity() && submit && (
                  <FormHelperText className="errorMsg" disabled={!hasWritePrivilege}>
                    Start Date should be before Contractuel End Date and Forecast End Date
                  </FormHelperText>
                )}
                <FormDate
                  onChange={(date) => {
                    dispatch(
                      setProjectFormData({
                        ...projectFormData,
                        contractualEndDate: date,
                      }),
                    );
                  }}
                  value={projectFormData?.contractualEndDate}
                  error={
                    (submit || isDraft) &&
                    !projectFormData.contractualEndDate &&
                    REQUIRED_ERROR
                  }
                  myLabel="Contractuel End Date*"
                  disabled={!hasWritePrivilege}
                />
              </Stack>
              <Stack className="form-split">
                <Stack>
                  <Stack className="mb-40">
                    <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
                      Platforms
                    </Typography>
                  </Stack>
                  <MultiselectForm
                    options={platforms?.map((platform) => platform?.name)}
                    value={platforms
                      ?.filter((platform) =>
                        selectedPlatforms?.map((p) => p?.id)?.includes(platform?.id),
                      )
                      ?.map((p) => p?.name)}
                    label="Platforms*"
                    onChange={(e) => {
                      addRemoveFromPlatforms(e.target.value);
                    }}
                    handleDelete={(e) => handleDeleteChipPlatform(e)}
                    error={
                      (submit || isDraft) &&
                      selectedPlatforms.length === 0 &&
                      REQUIRED_ERROR
                    }
                    disabled={!connectedUser?.admin}
                  />
                </Stack>
                <Stack>
                  <Stack className="mb-40">
                    <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
                      Timeline
                    </Typography>
                  </Stack>
                  <Stack className="form-split">
                    <FormInputPlusMinus
                      onChange={(e) => {
                        dispatch(
                          setProjectFormData({
                            ...projectFormData,
                            dailyWorkingHours: e,
                          }),
                        );
                      }}
                      value={projectFormData?.dailyWorkingHours}
                      label="Daily Working Hours"
                      fullLabelWidth
                      min={1}
                      max={12}
                      disabled={!hasWritePrivilege}
                    />
                    <FormDate
                      onChange={(date) => {
                        dispatch(
                          setProjectFormData({
                            ...projectFormData,
                            forecastDate: date,
                          }),
                        );
                      }}
                      value={projectFormData?.forecastDate}
                      error={submit && !projectFormData.forecastDate}
                      myLabel="Forecast End Date*"
                      disabled={!hasWritePrivilege}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <Stack>
                <Stack className="mb-40">
                  <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
                    Location
                  </Typography>
                </Stack>
                <Stack className="form-split">
                  <FormInput
                    onChange={(e) =>
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          homeOfficeLocation: e.target.value,
                        }),
                      )
                    }
                    value={projectFormData?.homeOfficeLocation}
                    label="Home Office location*"
                    error={
                      submit && !projectFormData.homeOfficeLocation && REQUIRED_ERROR
                    }
                    placeholder="Home Office location"
                    disabled={!hasWritePrivilege}
                  />

                  <FormInput
                    onChange={(e) =>
                      dispatch(
                        setProjectFormData({
                          ...projectFormData,
                          siteLocation: e.target.value,
                        }),
                      )
                    }
                    value={projectFormData?.siteLocation}
                    label="Site Location"
                    placeholder="Site Location"
                    disabled={!hasWritePrivilege}
                  />
                  <Stack className="fullWidth ">
                    <AppMap
                      handleSetLocation={(location) => {
                        handleSetUpdatedLocation(location, projectFormData);
                      }}
                      location={{
                        longitude: projectFormData?.longitude,
                        latitude: projectFormData?.latitude,
                      }}
                      title="Project location*"
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        {hasWritePrivilege && (
          <ProjectSettingsFooter
            cancelLabel="Cancel"
            cancelOnClick={() => handleOpenModal()}
            DraftLabel="Save as Draft"
            DraftOnClick={() => handleOpenDraftModal()}
            saveLabel="Save & continue"
            saveLeaveLabel="Save"
            saveOnClick={handleSaveContinue}
            showSaveLeaveBtn={projectId}
            showDraftBtn={projectCreationStatus === PROJECT_CREATION_STATUS.DRAFT}
          />
        )}
        {openModal && (
          <ConfirmationPopup
            width="500px"
            toOpen={openModal}
            popupType={POPUP_TYPE.CONFIRMATION_CANCEL__POPUP}
            onConfirm={navigateToProjectSettings}
            onCancel={handleOpenCloseModal}
            onClose={handleOpenCloseModal}
          />
        )}
        {openDraftModal && (
          <ProjectCreationPopup
            projectId={projectId || draftProjectId}
            toOpen={openDraftModal}
            onConfirm={handleSaveDraftProject}
            saveSuccess={projectSaveAsDraftSuccess}
            saveLoading={projectSaveAsDraftLoading}
            setOpenDraftModal={setOpenDraftModal}
            setShowSuccessPopup={setShowSuccessPopup}
          />
        )}
        {showSuccessPopup && (
          <ConfirmationPopup
            toOpen={showSuccessPopup}
            popupType={POPUP_TYPE.SUCCESS__POPUP}
            onCancel={onCancelSuccess}
            onClose={onCancelSuccess}
          />
        )}
      </ProjectsSettingsProjectCreationWrapper>
    </ThemeProvider>
  );
}

export default ProjectsSettingsProjectCreation;
