import { api } from '.';

const portfolioBaseUrl = '/portfolios';

export const portfolioApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPortfoliosByProgramCode: builder.query<
      any,
      { programCode: string; projectId: number }
    >({
      query: ({ programCode, projectId }) => ({
        url: `${portfolioBaseUrl}/by-program-code`,
        params: { programCode, projectId },
      }),
    }),
  }),
});

export const { useLazyGetPortfoliosByProgramCodeQuery } = portfolioApi;
