import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Stack, Typography } from '@mui/material';
import ActionButton from 'components/ActionButton';
import { LinkSvg } from 'assets/svgs/componentsIcons';
import AutocompleteInput from 'components/AutocompleteInput';
import {
  useLazyGetContractorsByProjectQuery,
  useLazyGetSubContractorsByProjectQuery,
  useLazyGetVendorsByProjectQuery,
  useLazySearchContractorsQuery,
  useLazySearchSubContractorsQuery,
  useLazySearchVendorsQuery,
} from 'services/apis/project-work-dimensions';
import { useDispatch, useSelector } from 'react-redux';
import {
  setContractors,
  setContractorsByProject,
  setSubContractors,
  setSubContractorsByProject,
  setVendors,
  setVendorsByProject,
} from 'services/slices/project-setting/project-work';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { PROJECT_WORK_DIMENSIONS_ALL_TABS } from 'pages/AppProjectsSettings/ProjectSettingProjectWorkDimensions/constants';
import usePrivilegeByFeature from 'utils/hooks/usePrivilegeByFeature';
import { Privileges } from 'utils/constants/Features';
import ProjectEntitiesItem from './ProjectEntitiesItem';
import ProjectEntitiesWrapper from './Wrapper';
import {
  makeSelectContractors,
  makeSelectContractorsByProject,
  makeSelectIsEntityDelete,
  makeSelectSubContractors,
  makeSelectSubContractorsByProject,
  makeSelectVendors,
  makeSelectVendorsByProject,
} from './selectors';
import { ERROR_MESSAGE } from './ProjectEntitiesItem/constants';

const stateSelector = createStructuredSelector({
  contractorsByProject: makeSelectContractorsByProject,
  contractors: makeSelectContractors,
  subContractorsByProject: makeSelectSubContractorsByProject,
  subContractors: makeSelectSubContractors,
  vendorByProject: makeSelectVendorsByProject,
  vendors: makeSelectVendors,
  isEntityDelete: makeSelectIsEntityDelete,
});

function ProjectEntities({ entityName, handleDeleteEntity }) {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const {
    contractors,
    subContractors,
    vendors,
    contractorsByProject,
    subContractorsByProject,
    vendorByProject,
  } = useSelector(stateSelector);

  const [
    doGetContractors,
    {
      currentData: { content: searchedContractorsList } = [],
      isSuccess: contractorsSuccess,
    },
  ] = useLazySearchContractorsQuery();
  const [
    doGetSubContractors,
    {
      currentData: { content: searchedSubContractorsList } = [],
      isSuccess: SubContractorsSuccess,
    },
  ] = useLazySearchSubContractorsQuery();
  const [
    doGetVendors,
    { currentData: { content: searchedVendorsList } = [], isSuccess: VendorsSuccess },
  ] = useLazySearchVendorsQuery();

  const [doGetContractorsByProject] = useLazyGetContractorsByProjectQuery();
  const [doGetSubContractorsByProject] = useLazyGetSubContractorsByProjectQuery();
  const [doGetVendorsByProject] = useLazyGetVendorsByProjectQuery();

  const ActualEntityName = PROJECT_WORK_DIMENSIONS_ALL_TABS[entityName].entityName;

  const entitiesConfig = {
    [PROJECT_WORK_DIMENSIONS_ALL_TABS.CONTRACTOR.code]: {
      entities: contractors,
      entitiesByProject: contractorsByProject,
      searchedEntitiesList: searchedContractorsList,
      dispatchSetEntitiesByProjectAction: setContractorsByProject,
      dispatchSetEntitiesAction: setContractors,
      getEntities: doGetContractors,
      getEntitiesByProject: doGetContractorsByProject,
      entityError: ERROR_MESSAGE.CONTRACTOR_ALREADY_EXIST,
      writePrivilege: usePrivilegeByFeature(
        PROJECT_WORK_DIMENSIONS_ALL_TABS.CONTRACTOR.feature,
        Privileges.WRITE,
      ),
    },
    [PROJECT_WORK_DIMENSIONS_ALL_TABS.SUB_CONTRACTOR.code]: {
      entities: subContractors,
      entitiesByProject: subContractorsByProject,
      searchedEntitiesList: searchedSubContractorsList,
      dispatchSetEntitiesByProjectAction: setSubContractorsByProject,
      dispatchSetEntitiesAction: setSubContractors,
      getEntities: doGetSubContractors,
      getEntitiesByProject: doGetSubContractorsByProject,
      entityError: ERROR_MESSAGE.SUB_CONTRACTOR_ALREADY_EXIST,
      writePrivilege: usePrivilegeByFeature(
        PROJECT_WORK_DIMENSIONS_ALL_TABS.CONTRACTOR.feature,
        Privileges.WRITE,
      ),
    },
    [PROJECT_WORK_DIMENSIONS_ALL_TABS.VENDOR.code]: {
      entities: vendors,
      entitiesByProject: vendorByProject,
      searchedEntitiesList: searchedVendorsList,
      dispatchSetEntitiesByProjectAction: setVendorsByProject,
      dispatchSetEntitiesAction: setVendors,
      getEntities: doGetVendors,
      getEntitiesByProject: doGetVendorsByProject,
      entityError: ERROR_MESSAGE.VENDOR_ALREADY_EXIST,
      writePrivilege: usePrivilegeByFeature(
        PROJECT_WORK_DIMENSIONS_ALL_TABS.VENDOR.feature,
        Privileges.WRITE,
      ),
    },
    target: 'entity',
  };

  const { entities, entitiesByProject, writePrivilege } = entitiesConfig[entityName];

  const entitiesByProjectIds = entitiesByProject
    ?.filter((ep) => !ep.entity?.hideItem)
    ?.map((ep) => ep.entity?.id);
  useEffect(() => {
    const fetchData = async () => {
      const getData = entitiesConfig[entityName].getEntitiesByProject;
      const result = await getData({ projectId: +projectId }).unwrap();
      dispatch(entitiesConfig[entityName].dispatchSetEntitiesByProjectAction(result));
    };
    fetchData();
  }, [
    dispatch,
    doGetContractorsByProject,
    doGetSubContractorsByProject,
    doGetVendorsByProject,
    projectId,
    entityName,
  ]);

  useEffect(() => {
    setError('');
    setName('');
  }, [entityName]);

  useEffect(() => {
    if (name.length >= 3) {
      entitiesConfig[entityName].getEntities({ projectId, name });
    }
  }, [doGetContractors, doGetSubContractors, doGetVendors, name, entityName]);

  useEffect(() => {
    const searchedList = entitiesConfig[entityName].searchedEntitiesList;

    if (contractorsSuccess || SubContractorsSuccess || VendorsSuccess) {
      dispatch(entitiesConfig[entityName].dispatchSetEntitiesAction(searchedList));
    }
  }, [
    dispatch,
    contractorsSuccess,
    searchedContractorsList,
    entityName,
    searchedSubContractorsList,
    SubContractorsSuccess,
    searchedVendorsList,
    VendorsSuccess,
  ]);

  const onDelete = (itemId) => {
    const selectedItemToDelete = entitiesByProject.find((el) => el?.entity.id === itemId);
    let updatedItemsList;
    if (!selectedItemToDelete?.id) {
      updatedItemsList = entitiesByProject.filter((el) => el.entity.id !== itemId);
    } else {
      updatedItemsList = entitiesByProject.map((el) => {
        if (el.id === selectedItemToDelete.id) {
          return {
            ...el,
            flagDeleted: true,
            [entitiesConfig.target]: { ...el[entitiesConfig.target], hideItem: true },
          };
        }

        return el;
      });
    }
    dispatch(
      entitiesConfig[entityName].dispatchSetEntitiesByProjectAction(updatedItemsList),
    );
  };

  const handleAssociateContractorToProject = () => {
    if (name !== '') {
      let updatedItemsByProjectList = [];

      const existingItem = entitiesByProject.find(
        (el) => el[entitiesConfig.target].name === name,
      );

      if (!existingItem) {
        const itemToAdd = entities.find((el) => el.name === name);

        if (itemToAdd) {
          const itemToAssociate = {
            projectId: +projectId,
            [entitiesConfig.target]: itemToAdd,
            flagDeleted: false,
          };
          updatedItemsByProjectList = [...entitiesByProject, itemToAssociate];

          dispatch(
            entitiesConfig[entityName].dispatchSetEntitiesByProjectAction(
              updatedItemsByProjectList,
            ),
          );
        }
      } else {
        setError(entitiesConfig[entityName].entityError);

        if (existingItem?.[entitiesConfig.target].hideItem) {
          updatedItemsByProjectList = entitiesByProject.map((el) => {
            if (el.id === existingItem.id) {
              const updatedItem = { ...el, flagDeleted: false };
              updatedItem[entitiesConfig.target] = {
                ...el[entitiesConfig.target],
                hideItem: false,
              };
              return updatedItem;
            }
            return el;
          });

          dispatch(
            entitiesConfig[entityName].dispatchSetEntitiesByProjectAction(
              updatedItemsByProjectList,
            ),
          );
        }
      }
      dispatch(entitiesConfig[entityName].dispatchSetEntitiesAction([]));
      setName('');
      setError('');
    } else {
      setError(ERROR_MESSAGE.NO_NAME);
    }
  };
  return (
    <ProjectEntitiesWrapper>
      {writePrivilege && (
        <Stack className=" mt-30">
          <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
            Add {ActualEntityName}
          </Typography>
          <Stack className="AddContractorForm">
            <Stack className="inputs">
              <AutocompleteInput
                error={error}
                isWithCheckbox={false}
                label={`${ActualEntityName} Name*`}
                placeholder="Type 3 characters to search..."
                inputValue={name}
                valueKey="name"
                options={entities?.filter((e) => !entitiesByProjectIds?.includes(e.id))}
                handleOnInputChange={(_, value, reason) => {
                  if (reason === 'input') {
                    setName(value);
                  }
                }}
                handleOnChange={(_, values) => {
                  setName(values[0].name);
                }}
              />
            </Stack>
            <ActionButton
              classname="footer--next__button associate"
              startIcon={<LinkSvg />}
              label="Associate"
              onClick={handleAssociateContractorToProject}
            />
          </Stack>
        </Stack>
      )}
      <Divider className="divider mb-25" />

      <Typography variant="h6_HelveticaNeue_Bold" color="text.main" className="mb-15">
        {ActualEntityName}
      </Typography>
      <Stack className="ContractorsListing">
        {entitiesByProject?.map((el) => (
          <ProjectEntitiesItem
            key={el.entity.id}
            target={entitiesConfig.target}
            item={el}
            onDelete={() => {
              handleDeleteEntity(() => {
                onDelete(el.entity.id);
              });
            }}
            disabled={!writePrivilege}
          />
        ))}
      </Stack>
    </ProjectEntitiesWrapper>
  );
}

ProjectEntities.propTypes = {
  entityName: PropTypes.string,
  handleDeleteEntity: PropTypes.func,
};
export default ProjectEntities;
