import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from 'assets/svgs/componentsIcons';
import CustomModal from 'components/StyledComponents/CustomModal';
import { Modal, Stack, Typography } from '@mui/material';
import { CloseButton, MainButton } from 'components/Buttons';
import SaveForLaterIcon from 'assets/svgs/componentsIcons/SaveForLaterIcon';
import SaveForUserIcon from 'assets/svgs/componentsIcons/SaveForUserIcon';
import AutocompleteInput from 'components/AutocompleteInput';
import { useLazyGetUsersByFullNameQuery } from 'services/apis/user';
import {
  CONFIRMATION_POPUP,
  DRAFT_PROJECT_CREATION_TYPES,
  POPUP_TYPE,
} from 'components/ConfirmationPopin/constants';
import { useDelegateProjectCreationToUserMutation } from 'services/apis/user-project';
import FullPageLoader from 'components/FullPageLoader';

function ProjectCreationPopup({
  projectId,
  toOpen,
  onConfirm,
  saveSuccess,
  saveLoading,
  setOpenDraftModal,
  setShowSuccessPopup,
}) {
  const [isForUser, setIsForUser] = useState(false);
  const [isActive, setIsActive] = useState(DRAFT_PROJECT_CREATION_TYPES.FOR_LATER);
  const [fullName, setFullName] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const POPUP_DATA = CONFIRMATION_POPUP[POPUP_TYPE.CONFIRMATION_DRAFT__POPUP];

  const [doGetUsersByFullName, { data: users = [] }] = useLazyGetUsersByFullNameQuery();
  const [
    doDelegateProjectCreationToUser,
    { isSuccess: delegateCreationSuccess, isLoading: delegateCreationLoading },
  ] = useDelegateProjectCreationToUserMutation();

  const handleDisplayName = (u) => {
    if (u.firstName && u.lastName) {
      return { ...u, fullName: `${u.firstName} ${u.lastName}` };
    }
    return { ...u, fullName: u.mail };
  };

  const handleExitDraftModal = () => {
    setOpenDraftModal(false);
    setShowSuccessPopup(true);
  };

  const handleActive = (visible, type) => {
    setIsForUser(visible);
    setIsActive(type);
  };

  const onCancel = () => {
    setOpenDraftModal(false);
  };

  useEffect(() => {
    if (fullName.length > 2) {
      doGetUsersByFullName({ fullName, projectId });
    }
  }, [fullName, doGetUsersByFullName]);

  useEffect(() => {
    if (delegateCreationSuccess) {
      handleExitDraftModal();
    }
  }, [delegateCreationSuccess]);

  useEffect(() => {
    if (saveSuccess) {
      if (
        isActive === DRAFT_PROJECT_CREATION_TYPES.FOR_ANOTHER_USER &&
        Object.keys(selectedUser).length > 0
      ) {
        doDelegateProjectCreationToUser({
          params: { userId: selectedUser?.id, projectId },
        });
      } else {
        handleExitDraftModal();
      }
    }
  }, [saveSuccess]);

  const validateAndConfirm = () => {
    if (
      isActive === DRAFT_PROJECT_CREATION_TYPES.FOR_ANOTHER_USER &&
      Object.keys(selectedUser)?.length === 0
    ) {
      setIsSubmit(true);
    } else {
      setIsSubmit(false);
      onConfirm();
    }
  };
  return (
    <>
      {(saveLoading || delegateCreationLoading) && <FullPageLoader />}
      <Modal
        open={toOpen}
        onClose={onCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomModal modalWidth="500px">
          <div className="modal-head-wrapper">
            <div className="modal-head">
              <h3 className="modal-title">{POPUP_DATA.title}</h3>
              <Stack className="saveContent">
                <Stack className="choicesItems">
                  <Stack
                    className={`choicesItem ${
                      isActive === DRAFT_PROJECT_CREATION_TYPES.FOR_LATER ? 'active' : ''
                    }`}
                    onClick={() =>
                      handleActive(false, DRAFT_PROJECT_CREATION_TYPES.FOR_LATER)
                    }
                  >
                    <Stack className="checkbox">
                      <Stack className="dot" />
                    </Stack>
                    <SaveForLaterIcon className="forLater" />
                    <Typography
                      variant="subtitle1_HelveticaNeue_Bold"
                      color="text.main"
                      className="mt-15"
                    >
                      Save for Later
                    </Typography>
                  </Stack>
                  <Stack
                    className={`choicesItem ${
                      isActive === DRAFT_PROJECT_CREATION_TYPES.FOR_ANOTHER_USER
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      handleActive(true, DRAFT_PROJECT_CREATION_TYPES.FOR_ANOTHER_USER)
                    }
                  >
                    <Stack className="checkbox">
                      <Stack className="dot" />
                    </Stack>
                    <SaveForUserIcon />
                    <Typography
                      variant="subtitle1_HelveticaNeue_Bold"
                      color="text.main"
                      className="mt-15"
                    >
                      Another user
                    </Typography>
                  </Stack>
                </Stack>
                <Stack className={`selectMember ${isForUser ? '' : 'hide'}`}>
                  <AutocompleteInput
                    label="Search Member"
                    placeholder="Type 3 characters to search..."
                    withChevronDown={false}
                    handleOnInputChange={(_, value, reason) => {
                      if (reason === 'input') {
                        setFullName(value);
                      }
                    }}
                    handleOnChange={(_, values) => {
                      setSelectedUser(values[0]);
                      setFullName(values[0]?.fullName);
                    }}
                    inputValue={fullName}
                    options={users?.map((u) => handleDisplayName(u))}
                    valueKey="fullName"
                    error={isSubmit && Object.keys(selectedUser)?.length === 0}
                  />
                </Stack>
              </Stack>
            </div>
            <div className="modal-closer">
              <CloseButton type="button" onClick={onCancel}>
                <CloseIcon />
              </CloseButton>
            </div>
          </div>

          <div className="modal-action-wrapper">
            {POPUP_DATA.cancelBtn && (
              <MainButton secondary onClick={onCancel}>
                {POPUP_DATA.cancelBtn}
              </MainButton>
            )}
            {POPUP_DATA.confirmationBtn && (
              <MainButton onClick={validateAndConfirm}>
                {POPUP_DATA.confirmationBtn}
              </MainButton>
            )}
          </div>
        </CustomModal>
      </Modal>
    </>
  );
}

ProjectCreationPopup.propTypes = {
  toOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  projectId: PropTypes.number,
  saveSuccess: PropTypes.bool,
  saveLoading: PropTypes.bool,
  setShowSuccessPopup: PropTypes.func,
  setOpenDraftModal: PropTypes.func,
};

export default ProjectCreationPopup;
