import { api } from '.';

const enumerationBaseUrl = '/enumerations';

export enum PlatformSettingsTypesEnum {
  SECTOR = 'SECTOR',
  PROGRAM = 'PROGRAM',
  BU = 'BU',
  DISCIPLINE = 'DISCIPLINE',
  CLIENT = 'CLIENT',
}

export enum EnumerationType {
  PHASE = 'PHASE',
  PLATFORM_SETTINGS = 'PLATFORM_SETTINGS',
  PLATFORM_CATEGORIES = 'PLATFORM_CATEGORIES',
  SPECIFIC_ROLE_TYPE = 'SPECIFIC_ROLE_TYPE',
  EXTERNAL_USER_ROLE_TYPE = 'EXTERNAL_USER_ROLE_TYPE',
  PROJECT_ADMIN_DATA = 'PROJECT_ADMIN_DATA',
  PROJECT_STATUS = 'PROJECT_STATUS',
  PROJECT_STATE = 'PROJECT_STATE',
  PROJECT_SIZE = 'PROJECT_SIZE',
  PROJECT_SCOPE = 'PROJECT_SCOPE',
  RISK_CLASSIFICATION = 'RISK_CLASSIFICATION',
  PROJECT_TYPE_CONTRACT = 'PROJECT_TYPE_CONTRACT',
  PROJECT_COMMERCIAL_CONTRACT = 'PROJECT_COMMERCIAL_CONTRACT',
  PROJECT_GREEN_FIELD = 'PROJECT_GREEN_FIELD',
}

export interface Enumeration {
  id: number;
  type: EnumerationType;
  code: string;
  label: string;
}

export type EnumerationByTypesResponse = {
  [key in Partial<EnumerationType>]: Enumeration[];
};

export const enumerationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEnumerationByTypeFromUserManagement: builder.query<Enumeration[], EnumerationType>(
      {
        query: (type) => ({
          url: `${enumerationBaseUrl}/user-management/${type}`,
        }),
      },
    ),
    getEnumerationsByTypes: builder.query<
      EnumerationByTypesResponse,
      { types: EnumerationType[]; projectId: number }
    >({
      query: ({ types, projectId }) => ({
        url: `${enumerationBaseUrl}/list-by-type`,
        params: { types, projectId },
      }),
    }),
  }),
});

export const {
  useGetEnumerationByTypeFromUserManagementQuery,
  useGetEnumerationsByTypesQuery,
  useLazyGetEnumerationsByTypesQuery,
} = enumerationApi;
