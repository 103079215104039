export const CONFIRMATION_POPUP = {
  CREATE_EXTERNAL_USER__CANCEL: {
    title: 'Add External User',
    message: () => 'Are you sure you want to cancel the creation of this external user ?',
    confirmationBtn: 'Yes',
    cancelBtn: 'NO',
  },
  CREATE_EXTERNAL_USER__SUCCESS: {
    title: 'Edit External User',
    message: () => 'The user data is successfully saved',
  },
  CONFIRMATION__POPUP: {
    title: 'Confirmation',
    message: () => 'Are you sure you want to abandon your changes in the current page ?',
    confirmationBtn: 'Yes',
    cancelBtn: 'NO',
  },
  DRAFT__POPUP: {
    title: 'Information',
    confirmationBtn: 'Save',
    cancelBtn: 'Cancel',
  },
  SUCCESS__POPUP: {
    title: 'Success',
    message: () => 'The project information are saved successfully',
  },
  CONFIRMATION_BACK__POPUP: {
    title: 'Confirmation',
    message: () =>
      'Are you sure you want to save your changes to be able to move to the other step ?',
    confirmationBtn: 'Yes',
    cancelBtn: 'NO',
  },
  CONFIRMATION_TAB__POPUP: {
    title: 'Confirmation',
    message: (entityName = '') =>
      `Are you sure you want to save your changes to be able to move to the other ${entityName} tab ?`,
    confirmationBtn: 'Yes',
    cancelBtn: 'NO',
  },
  CONFIRMATION_DELETE__POPUP: {
    title: 'Delete',
    message: (entityName = '') => `Are you sure you want to delete this ${entityName} ?`,
    deleteBtn: 'Yes',
    cancelBtn: 'No',
  },
  PROFILE_SUCCESS__POPUP: {
    title: 'Success',
    message: () => 'The profile information are saved successfully',
  },
  BACK__POPUP: {
    title: 'Confirmation',
    message: () => 'Are you sure you want to abandon your changes ?',
    confirmationBtn: 'Yes',
    cancelBtn: 'NO',
  },
  CONFIRMATION_DELETE_PROFILE: {
    title: 'Delete',
    message: () => 'Are you sure you want to delete this profile',
    deleteBtn: 'Yes',
    cancelBtn: 'No',
  },
  ERROR_DELETE_PROFILE: {
    title: 'Error',
    message: (error: string) => error,
  },
  SUCCESS_DELETE_PROFILE: {
    title: 'Success',
    message: () => 'The profile has been deleted successfully',
  },
  EDIT_USER_PROJECT_PROFILE: {
    title: 'Confirmation',
    message: () => 'Are you sure you want to leave before saving your changes ?',
    confirmationBtn: 'Yes',
    cancelBtn: 'NO',
  },
  ACCESS_DENIED: {
    title: 'Access Denied',
    message: () => 'Your access privileges are limited, you can not access this view',
  },
  EDIT_USER_PROFILE: {
    title: 'Edit user profile',
    message: () => 'The projects and profile are related to the selected platform',
    confirmationBtn: 'Save',
    cancelBtn: 'Cancel',
  },
  DELETE_USER: {
    title: 'Delete user from project',
    message: () =>
      'Are you sure you want to delete the selected user from this project ?',
    confirmationBtn: 'Yes',
    cancelBtn: 'No',
  },
  DELETE_USER_SUCCESS: {
    title: 'Delete user success',
    message: () =>
      'The user has been disassociated from the selected project successfully',
  },
};

export const POPUP_TYPE = {
  CREATE_EXTERNAL_USER__SUCCESS: 'CREATE_EXTERNAL_USER__SUCCESS',
  CREATE_EXTERNAL_USER__CANCEL: 'CREATE_EXTERNAL_USER__CANCEL',
  CONFIRMATION_CANCEL__POPUP: 'CONFIRMATION__POPUP',
  CONFIRMATION_DRAFT__POPUP: 'DRAFT__POPUP',
  SUCCESS__POPUP: 'SUCCESS__POPUP',
  CONFIRMATION_BACK__POPUP: 'CONFIRMATION_BACK__POPUP',
  CONFIRMATION_TAB__POPUP: 'CONFIRMATION_TAB__POPUP',
  CONFIRMATION_DELETE__POPUP: 'CONFIRMATION_DELETE__POPUP',
  PROFILE_SUCCESS__POPUP: 'PROFILE_SUCCESS__POPUP',
  BACK__POPUP: 'BACK__POPUP',
  CONFIRMATION_DELETE_PROFILE: 'CONFIRMATION_DELETE_PROFILE',
  ERROR_DELETE_PROFILE: 'ERROR_DELETE_PROFILE',
  SUCCESS_DELETE_PROFILE: 'SUCCESS_DELETE_PROFILE',
  EDIT_USER_PROJECT_PROFILE: 'EDIT_USER_PROJECT_PROFILE',
  ACCESS_DENIED: 'ACCESS_DENIED',
  EDIT_USER_PROFILE: 'EDIT_USER_PROFILE',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
};

export enum DRAFT_PROJECT_CREATION_TYPES {
  FOR_LATER,
  FOR_ANOTHER_USER,
}
