import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { POPUP_TYPE } from 'components/ConfirmationPopin/constants';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import MultiselectForm from 'components/MultiSelectForm';
import ConfirmationPopup from 'components/ConfirmationPopin';
import { PlatformEnum, useGetPlatformsByCodesQuery } from 'services/apis/platform';
import { RoleCategoriesEnum, useGetRolesByCategoriesQuery } from 'services/apis/role';
import { useGetAllSimplifiedProjectsQuery } from 'services/apis/project';
import { useGetProfilesByUserTypeQuery } from 'services/apis/access-control/profile';
import { useAssignManyProjectsToUserMutation } from 'services/apis/user-project';
import { useDispatch } from 'react-redux';
import {
  setAssignManyProjectsToUserSuccess,
  setSelectedPlatform,
} from 'services/slices/user-management/user-project-profile';

function AddEditUserPopup({ toOpen, onClose, onCancel, userDetail }) {
  const dispatch = useDispatch();
  const [roleCategories, setRoleCategories] = useState([]);
  const [userProject, setUserProject] = useState({});
  const [projectByPlatform, setProjectByPlatform] = useState([]);
  const [profileByPlatform, setProfileByPlatform] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [submit, setSubmit] = useState(false);
  const { data: platforms } = useGetPlatformsByCodesQuery([
    PlatformEnum.COLLAB,
    PlatformEnum.ESP,
    PlatformEnum.JPASS,
  ]);
  const { data: profiles } = useGetProfilesByUserTypeQuery({
    isExternal: userDetail?.external,
  });
  const { data: projects } = useGetAllSimplifiedProjectsQuery();
  const { data: roles } = useGetRolesByCategoriesQuery(
    { categories: roleCategories, projectId: null },
    {
      skip: !roleCategories.length,
    },
  );
  const [doAssignManyProjectsToUser, { isSuccess: assignManyProjectsToUserSuccess }] =
    useAssignManyProjectsToUserMutation();
  // getRoles by categories
  useEffect(() => {
    if (userDetail) {
      const categories = userDetail?.external
        ? [RoleCategoriesEnum.EXTERNAL_CLIENT, RoleCategoriesEnum.EXTERNAL_CONTRACTOR]
        : [RoleCategoriesEnum.JESA];
      setRoleCategories(categories);
    }
  }, [userDetail]);

  // get filtered Profiles and Projects
  useEffect(() => {
    if (userProject?.platformId) {
      if (projects) {
        const projectsList = projects?.filter((p) =>
          p.platforms?.map((pl) => pl.id).includes(userProject?.platformId),
        );
        setProjectByPlatform(projectsList);
      }
      if (profiles) {
        const profileList = profiles?.filter(
          (p) => p.platform?.id === userProject?.platformId,
        );
        setProfileByPlatform(profileList);
      }
    }
  }, [userProject?.platformId]);

  useEffect(() => {
    if (assignManyProjectsToUserSuccess) {
      dispatch(setAssignManyProjectsToUserSuccess(assignManyProjectsToUserSuccess));
    }
  }, [assignManyProjectsToUserSuccess]);

  const formatNumberName = (item) => `${item?.number}-${item?.name}`;

  const addRemoveFromProjects = (value) => {
    const selectedProjectIds = projectByPlatform
      ?.filter((p) => value.includes(formatNumberName(p)))
      .map((p) => p?.id);
    setSelectedProjects([...selectedProjectIds]);
  };
  const deleteProject = (projectToDelete) => {
    const selectedItem = projectByPlatform?.filter(
      (item) => formatNumberName(item) === projectToDelete,
    );
    const updatedSelectedValues = selectedProjects.filter(
      (selectedProject) => selectedProject !== selectedItem[0]?.id,
    );
    setSelectedProjects(updatedSelectedValues);
  };

  const checkFormValidity = () =>
    userProject?.platformId &&
    userProject?.profileId &&
    userProject?.roleId &&
    userDetail?.id &&
    selectedProjects?.length > 0;

  const onConfirm = () => {
    setSubmit(true);
    if (checkFormValidity()) {
      doAssignManyProjectsToUser({
        ...userProject,
        projectIds: selectedProjects,
        userId: userDetail?.id,
      });
    }
  };
  return (
    <ConfirmationPopup
      width="1040px"
      actionsClassName="editPopupActions"
      popupType={POPUP_TYPE.EDIT_USER_PROFILE}
      toOpen={toOpen}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <Stack className="addProfileStyle">
        <Stack className="form-split">
          <FormInput
            disabled
            label="User"
            placeholder="User profile"
            value={userDetail?.fullName || '-'}
          />
          <FormSelect
            label="Project role *"
            placeholder="Project role"
            menuItems={roles}
            onChange={(e) => {
              setUserProject({ ...userProject, roleId: e.target.value?.id });
            }}
            value={roles?.find((role) => role?.id === userProject?.roleId)}
            error={submit && !userProject?.roleId}
          />
        </Stack>
        <Stack className="form-split">
          <Stack>
            <FormSelect
              menuItems={platforms}
              onChange={(e) => {
                dispatch(setSelectedPlatform(e.target.value?.code));
                setUserProject({ ...userProject, platformId: e.target.value?.id });
              }}
              value={platforms?.find(
                (platform) => platform?.id === userProject?.platformId,
              )}
              label="Platform *"
              valueKey="name"
              error={submit && !userProject?.platformId}
            />
            <FormSelect
              label="Profile *"
              placeholder="Depends"
              customClassName="noHeight"
              menuItems={profileByPlatform}
              onChange={(e) => {
                setUserProject({ ...userProject, profileId: e.target.value?.id });
              }}
              value={profileByPlatform?.find(
                (platform) => platform?.id === userProject?.profileId,
              )}
              valueKey="name"
              error={submit && !userProject?.profileId}
              disabled={!userProject?.platformId}
            />
          </Stack>
          <MultiselectForm
            options={projectByPlatform.map((project) => formatNumberName(project))}
            label="Project *"
            value={projectByPlatform
              .filter((project) => selectedProjects.includes(project?.id))
              ?.map((p) => formatNumberName(p))}
            onChange={(e) => {
              addRemoveFromProjects(e.target.value);
            }}
            handleDelete={deleteProject}
            valueKey="name"
            error={submit && !selectedProjects.length}
            disabled={!userProject?.platformId}
          />
        </Stack>
      </Stack>
    </ConfirmationPopup>
  );
}

AddEditUserPopup.propTypes = {
  toOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  userDetail: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    external: PropTypes.bool,
  }),
};
export default AddEditUserPopup;
