import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import FormSelect from 'components/FormSelect';
import EditIconProfile from 'assets/svgs/componentsIcons/EditIconProfile';
import { DeleteWL } from 'assets/svgs/componentsIcons';
import { useEditUserProjectProfileMutation } from 'services/apis/user-project-profile';
import { useDispatch } from 'react-redux';
import {
  addUpdatedItems,
  removeUpdatedItem,
} from 'services/slices/user-management/user-project-profile';
import ConfirmationPopup from 'components/ConfirmationPopin';
import { POPUP_TYPE } from 'components/ConfirmationPopin/constants';

function UserProjectProfileItem({
  item,
  handleGetUserProjectProfiles,
  profiles,
  hideDelete = false,
}) {
  const dispatch = useDispatch();
  const [selectedProfile, setSelectedProfile] = useState(item?.profile);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [doEditUserProjectProfile, { isSuccess: editUserProjectProfileSuccess }] =
    useEditUserProjectProfileMutation();

  const onCancelConfirmation = () => {
    setShowConfirmationPopup(false);
  };
  const confirmDelete = () => {
    doEditUserProjectProfile({ ...item, deleted: true });
    setShowConfirmationPopup(false);
    setShowDeleteSuccessPopup(true);
  };
  const onDelete = () => {
    setShowConfirmationPopup(true);
  };

  const handleSaveEditProfile = () => {
    if (item?.profile?.code !== selectedProfile?.code) {
      doEditUserProjectProfile({ ...item, profile: selectedProfile });
    } else {
      setIsEditProfile(false);
    }
  };
  const handleClickOnEdit = () => {
    setIsEditProfile((prevState) => !prevState);
  };
  const handleChange = (value) => {
    if (selectedProfile?.code !== value?.code) {
      dispatch(addUpdatedItems(value?.id));
    }
    setSelectedProfile(value);
  };
  useEffect(() => {
    if (editUserProjectProfileSuccess) {
      dispatch(removeUpdatedItem(selectedProfile?.id));
      handleGetUserProjectProfiles();
      setIsEditProfile(false);
    }
  }, [editUserProjectProfileSuccess]);

  return (
    <>
      <tr key={item?.id}>
        <td> {item?.userProject?.project?.name} </td>
        <td> {item?.userProject?.project?.number} </td>
        <td> {item?.userProject?.role?.label} </td>
        <td>
          {!isEditProfile ? (
            <Typography variant="subtitle2_HelveticaNeue_Regular">
              {item?.profile?.name}
            </Typography>
          ) : (
            <FormSelect
              customClassName="profileSelect"
              menuItems={profiles}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              value={profiles?.find((profile) => profile?.name === selectedProfile?.name)}
              valueKey="name"
            />
          )}
        </td>
        <td>
          <Stack className="btnManagers">
            {!isEditProfile ? (
              <button
                type="button"
                className="editUser ml-10"
                onClick={handleClickOnEdit}
              >
                <EditIconProfile />
                <Typography
                  variant="subtitle2_HelveticaNeue_Regular"
                  color="primary.main"
                >
                  Edit profile
                </Typography>
              </button>
            ) : (
              <button
                type="button"
                className="saveUser ml-10"
                onClick={handleSaveEditProfile}
              >
                <Typography
                  variant="subtitle2_HelveticaNeue_Regular"
                  color="common.white"
                >
                  Save
                </Typography>
              </button>
            )}
            <button
              type="button"
              className={`deleteBtn ${hideDelete ? 'hide' : ''}`}
              onClick={onDelete}
            >
              <DeleteWL />
            </button>
          </Stack>
        </td>
      </tr>
      {showConfirmationPopup && (
        <ConfirmationPopup
          toOpen={showConfirmationPopup}
          popupType={POPUP_TYPE.DELETE_USER}
          onCancel={onCancelConfirmation}
          onClose={onCancelConfirmation}
          onConfirm={confirmDelete}
        />
      )}
      {showDeleteSuccessPopup && (
        <ConfirmationPopup
          toOpen={showDeleteSuccessPopup}
          popupType={POPUP_TYPE.DELETE_USER_SUCCESS}
          onClose={() => {
            setShowDeleteSuccessPopup(false);
          }}
          onCancel={() => {
            setShowDeleteSuccessPopup(false);
          }}
        />
      )}
    </>
  );
}

UserProjectProfileItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    profile: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
    userProject: PropTypes.shape({
      project: PropTypes.shape({
        name: PropTypes.string,
        number: PropTypes.number,
      }),
      role: PropTypes.shape({
        label: PropTypes.string,
      }),
    }),
  }),
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  handleGetUserProjectProfiles: PropTypes.func,
  hideDelete: PropTypes.bool,
};
export default UserProjectProfileItem;
