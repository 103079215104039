import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { DragDropContext } from '@hello-pangea/dnd';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetAllQuery } from 'services/apis/platform-settings';
import { updateDisciplineSelected } from 'services/slices/project-setting/project-work';
import { PLATFORM_SETTINGS_TYPES } from 'utils/constants/specificRoles';
import usePrivilegeByFeature from 'utils/hooks/usePrivilegeByFeature';
import { Features, Privileges } from 'utils/constants/Features';
import PropTypes from 'prop-types';
import { makeSelectSelectedEntityInit } from '../selectors';
import DroppedContainer from './Wrapper';
import ProjectDroppableList from './ProjectDroppableList';

function ProjectDiscipline({ projectId }) {
  const stateSelector = createStructuredSelector({
    selectedEntityInit: makeSelectSelectedEntityInit,
  });
  const { selectedEntityInit } = useSelector(stateSelector);
  const [doGetAll] = useLazyGetAllQuery();
  const [leftList, setLeftList] = useState([]);

  const [rightList, setRightList] = useState([]);
  const dispatch = useDispatch();

  const mapDiscipline = (item) => ({
    id: item.id.toString(),
    content: item.name,
  });
  const hasWritePrivilege = usePrivilegeByFeature(
    Features.DS_PROJECT_AREA_SYSTEM,
    Privileges.WRITE,
  );
  useEffect(() => {
    doGetAll({
      params: {
        projectId,
      },
      baseRoute: PLATFORM_SETTINGS_TYPES.DISCIPLINE_HEAD.baseRoute,
    }).then(({ data }) => {
      const selectedDisciplineIds = selectedEntityInit?.map((item) => item.id);
      const filteredData = data?.filter(
        (item) => !selectedDisciplineIds.includes(item.id),
      );

      setRightList(selectedEntityInit?.map(mapDiscipline));
      setLeftList(filteredData?.map(mapDiscipline));
    });

    dispatch(updateDisciplineSelected(selectedEntityInit));
  }, [selectedEntityInit, dispatch]);

  const handelChangeData = () => {
    const disciplineSelected = rightList?.map((item) => ({
      id: parseInt(item.id, 10),
      name: item.content,
    }));
    dispatch(updateDisciplineSelected(disciplineSelected));
  };
  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const sourceList = source.droppableId === 'leftList' ? leftList : rightList;
      const destinationList =
        destination.droppableId === 'leftList' ? leftList : rightList;
      const [movedItem] = sourceList.splice(source.index, 1);
      destinationList.splice(destination.index, 0, movedItem);

      setLeftList([...leftList]);
      setRightList([...rightList]);
      handelChangeData();
    }
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <DroppedContainer>
        <Stack className="leftDragDrop mt-30">
          <Typography variant="subtitle2_HelveticaNeue_Medium">
            Drag or add new discipline
          </Typography>
          <ProjectDroppableList
            droppableId="leftList"
            items={leftList}
            isRightList={false}
            disabled={!hasWritePrivilege}
          />
        </Stack>
        <Stack className="rightDragDrop mt-30">
          <Typography variant="subtitle2_HelveticaNeue_Medium">
            Drag or add new discipline
          </Typography>
          <ProjectDroppableList
            classname="rightDragDropList"
            droppableId="rightList"
            items={rightList}
            isRightList
            disabled={!hasWritePrivilege}
          />
        </Stack>
      </DroppedContainer>
    </DragDropContext>
  );
}
ProjectDiscipline.propTypes = {
  projectId: PropTypes.number.isRequired,
};
export default ProjectDiscipline;
